// src/components/StyledButton.js

import styled from 'styled-components';

const StyledButton = styled.button`
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
`;

export default StyledButton;
