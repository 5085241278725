import axios from 'axios';

const API_BASE_URL = 'api/chat/share/configuration';

const loadConfig = async (hostname, apiKey) => {
    try {
        const response = await axios.get(`${hostname}/${API_BASE_URL}?api_key=${apiKey}`);
        return response.data?.configuration;
    } catch (error) {
        console.error('Error loading configuration:', error);
        return null;
    }
};

export default loadConfig;
