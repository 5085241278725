// src/MessageIcon.js

import React from 'react';
import styled from 'styled-components';
import Avatar from '@mui/material/Avatar';

const MessageIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  width: 50px;
  min-width: 50px;
  height: 50px;
  background-color: transparent;
  border-radius: 25px;
  color: #ffffff;
  position: absolute;
  z-index: 1000;
`;

const StyledAvatar = styled(Avatar)`
  background-color: ${(props) => props.$backgroundColor || 'transparent'} !important;
  padding: 5px;
  height: 30px;
  width: 30px;
  max-width: 30px;
  max-height: 30px;
`;

function MessageIcon({ configuration }) {
    if (!configuration.avatar_logo) {
        return null;
    }

    return (
        <MessageIconContainer >
            <StyledAvatar
                alt="logo"
                src={configuration.avatar_logo}
                $backgroundColor={configuration.avatar_background_color || 'transparent'}
                imgProps={{ style: { objectFit: 'contain' } }}
            />
        </MessageIconContainer>
    );
}

export default MessageIcon;
