import React, { useMemo } from 'react';
import styled, { keyframes, css } from 'styled-components';

const generateMoveBox = (translate, boxNumber) => {
  switch (boxNumber) {
    case 1:
      return keyframes`
        9.09% { transform: translate(-${translate}px, 0); }
        18.18% { transform: translate(0px, 0); }
        27.27% { transform: translate(0px, 0); }
        36.36% { transform: translate(${translate}px, 0); }
        45.45% { transform: translate(${translate}px, ${translate}px); }
        54.54% { transform: translate(${translate}px, ${translate}px); }
        63.63% { transform: translate(${translate}px, ${translate}px); }
        72.72% { transform: translate(${translate}px, 0px); }
        81.81% { transform: translate(0px, 0px); }
        90.90% { transform: translate(-${translate}px, 0px); }
        100% { transform: translate(0px, 0px); }
      `;
    case 2:
      return keyframes`
        9.09% { transform: translate(0px, 0); }
        18.18% { transform: translate(${translate}px, 0); }
        27.27% { transform: translate(0px, 0); }
        36.36% { transform: translate(${translate}px, 0); }
        45.45% { transform: translate(${translate}px, ${translate}px); }
        54.54% { transform: translate(${translate}px, ${translate}px); }
        63.63% { transform: translate(${translate}px, ${translate}px); }
        72.72% { transform: translate(${translate}px, ${translate}px); }
        81.81% { transform: translate(0px, ${translate}px); }
        90.90% { transform: translate(0px, ${translate}px); }
        100% { transform: translate(0px, 0px); }
      `;
    case 3:
      return keyframes`
        9.09% { transform: translate(-${translate}px, 0); }
        18.18% { transform: translate(-${translate}px, 0); }
        27.27% { transform: translate(0px, 0); }
        36.36% { transform: translate(-${translate}px, 0); }
        45.45% { transform: translate(-${translate}px, 0); }
        54.54% { transform: translate(-${translate}px, 0); }
        63.63% { transform: translate(-${translate}px, 0); }
        72.72% { transform: translate(-${translate}px, 0); }
        81.81% { transform: translate(-${translate}px, -${translate}px); }
        90.90% { transform: translate(0px, -${translate}px); }
        100% { transform: translate(0px, 0px); }
      `;
    case 4:
      return keyframes`
        9.09% { transform: translate(-${translate}px, 0); }
        18.18% { transform: translate(-${translate}px, 0); }
        27.27% { transform: translate(-${translate}px, -${translate}px); }
        36.36% { transform: translate(0px, -${translate}px); }
        45.45% { transform: translate(0px, 0px); }
        54.54% { transform: translate(0px, -${translate}px); }
        63.63% { transform: translate(0px, -${translate}px); }
        72.72% { transform: translate(0px, -${translate}px); }
        81.81% { transform: translate(-${translate}px, -${translate}px); }
        90.90% { transform: translate(-${translate}px, 0px); }
        100% { transform: translate(0px, 0px); }
      `;
    case 5:
      return keyframes`
        9.09% { transform: translate(0px, 0); }
        18.18% { transform: translate(0px, 0); }
        27.27% { transform: translate(0px, 0); }
        36.36% { transform: translate(${translate}px, 0); }
        45.45% { transform: translate(${translate}px, 0); }
        54.54% { transform: translate(${translate}px, 0); }
        63.63% { transform: translate(${translate}px, 0); }
        72.72% { transform: translate(${translate}px, 0); }
        81.81% { transform: translate(${translate}px, -${translate}px); }
        90.90% { transform: translate(0px, -${translate}px); }
        100% { transform: translate(0px, 0px); }
      `;
    case 6:
      return keyframes`
        9.09% { transform: translate(0px, 0); }
        18.18% { transform: translate(-${translate}px, 0); }
        27.27% { transform: translate(-${translate}px, 0); }
        36.36% { transform: translate(0px, 0); }
        45.45% { transform: translate(0px, 0); }
        54.54% { transform: translate(0px, 0); }
        63.63% { transform: translate(0px, 0); }
        72.72% { transform: translate(0px, ${translate}px); }
        81.81% { transform: translate(-${translate}px, ${translate}px); }
        90.90% { transform: translate(-${translate}px, 0px); }
        100% { transform: translate(0px, 0px); }
      `;
    case 7:
      return keyframes`
        9.09% { transform: translate(${translate}px, 0); }
        18.18% { transform: translate(${translate}px, 0); }
        27.27% { transform: translate(${translate}px, 0); }
        36.36% { transform: translate(0px, 0); }
        45.45% { transform: translate(0px, -${translate}px); }
        54.54% { transform: translate(${translate}px, -${translate}px); }
        63.63% { transform: translate(0px, -${translate}px); }
        72.72% { transform: translate(0px, -${translate}px); }
        81.81% { transform: translate(0px, 0px); }
        90.90% { transform: translate(${translate}px, 0px); }
        100% { transform: translate(0px, 0px); }
      `;
    case 8:
      return keyframes`
        9.09% { transform: translate(0px, 0); }
        18.18% { transform: translate(-${translate}px, 0); }
        27.27% { transform: translate(-${translate}px, -${translate}px); }
        36.36% { transform: translate(0px, -${translate}px); }
        45.45% { transform: translate(0px, -${translate}px); }
        54.54% { transform: translate(0px, -${translate}px); }
        63.63% { transform: translate(0px, -${translate}px); }
        72.72% { transform: translate(0px, -${translate}px); }
        81.81% { transform: translate(${translate}px, -${translate}px); }
        90.90% { transform: translate(${translate}px, 0px); }
        100% { transform: translate(0px, 0px); }
      `;
    case 9:
      return keyframes`
        9.09% { transform: translate(-${translate}px, 0); }
        18.18% { transform: translate(-${translate}px, 0); }
        27.27% { transform: translate(0px, 0); }
        36.36% { transform: translate(-${translate}px, 0); }
        45.45% { transform: translate(0px, 0); }
        54.54% { transform: translate(0px, 0); }
        63.63% { transform: translate(-${translate}px, 0); }
        72.72% { transform: translate(-${translate}px, 0); }
        81.81% { transform: translate(-${translate * 2}px, 0); }
        90.90% { transform: translate(-${translate}px, 0); }
        100% { transform: translate(0px, 0); }
      `;
    default:
      return keyframes``;
  }
};

const Loader = styled.div`
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  display: flex;
  flex-wrap: wrap;
`;

const Box = styled.div`
  position: relative;
  width: ${({ $boxSize }) => $boxSize}px;
  height: ${({ $boxSize }) => $boxSize}px;
  margin-right: ${({ $isThird, $margin }) => ($isThird ? '0' : `${$margin}px`)};
  margin-bottom: ${({ $isLast, $margin }) => $isLast ? '0' : `${$margin}px`};
  animation: ${({ $animation }) => css`${$animation}`} 4s infinite;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: ${({ $color }) => $color};
    margin-left: ${({ $marginLeftBefore }) => $marginLeftBefore}px;
    margin-top: ${({ $marginTopBefore }) => $marginTopBefore}px;
  }
`;


const CustomLoader = ({ size, color, style = {} }) => {
  const margin = useMemo(() => size / 12, [size]); // 72 / 12 = 6px
  const boxSize = useMemo(() => (size - 2 * margin) / 3, [size, margin]);
  const translate = useMemo(() => boxSize + margin, [boxSize, margin]); // 20 + 6 = 26px

  const animations = useMemo(
    () =>
      Array.from({ length: 9 }, (_, index) => generateMoveBox(translate, index + 1)),
    [translate]
  );

  return (
    <Loader $size={size} $color={color} style={style}>
      {animations.map((animation, index) => {
        const marginLeftBefore = (index === 0 || index === 3) ? translate : 0;
        const marginTopBefore = (index === 2) ? translate * 2 : 0;

        const isThird = (index + 1) % 3 === 0;
        const isLast = index === 8;

        return (
          <Box
            key={index}
            $boxSize={boxSize}
            $color={color}
            $margin={margin}
            $animation={animation}
            $isThird={isThird}
            $isLast={isLast}
            $marginLeftBefore={marginLeftBefore}
            $marginTopBefore={marginTopBefore}
          />
        );
      })}


    </Loader>
  );
};

export default CustomLoader;
