import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { FaRegClipboard, FaClipboardCheck } from 'react-icons/fa';
import rehypeAttr from 'rehype-attr';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';

// Wrapper stylisé pour appliquer des styles aux éléments markdown
const MarkdownWrapper = styled.div`

    ul {
        margin: 0;
    }

    a {
        text-decoration: underline;
    }

    p code {
        padding: 2px 4px;
        font-family: 'Courier New', Courier, monospace;
        font-size: 90%;
    }

`;

const MarkdownWrapperLanding = styled.div`
    h1 {
        font-family: Arial, sans-serif;
        border-bottom: 2px solid #eee;
        padding-bottom: 10px;
    }

    p {
        line-height: 1.6;
        font-size: 16px;
        margin:0;
    }

    ul {
        margin: 0;
    }

    a {
        text-decoration: underline;
    }

    p code {
        padding: 2px 4px;
        font-family: 'Courier New', Courier, monospace;
        font-size: 90%;
    }

    li, ol {
        white-space: normal;
    }
`;

// Wrapper pour le bloc de code et l'icône de copie
const CodeBlockWrapper = styled.div`
    position: relative;
`;

const CopyIcon = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    color: #ddd;
    transition: color 0.3s;

    &:hover {
        color: #fff;
    }
`;

// Composant personnalisé pour les blocs de code
const CodeBlock = ({ node, inline, className, children, ...props }) => {
    const [isCopied, setIsCopied] = useState(false);
    const language = className ? className.replace('language-', '') : '';

    if (node.tagName === 'code' && !language) {
        return (
            <code className={className} {...props}>
                {children}
            </code>
        );
    }

    const code = children && Array.isArray(children) ? children.map(String).join('') : String(children);

    const handleCopy = () => {
        navigator.clipboard.writeText(code).then(() => {
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 2000);
        });
    };

    return (
        <CodeBlockWrapper>
            <SyntaxHighlighter
                language={language}
                style={darcula}
                PreTag="pre"
                {...props}
            >
                {code.replace(/\n$/, '')}
            </SyntaxHighlighter>
            <CopyIcon onClick={handleCopy}>
                {isCopied ? <FaClipboardCheck /> : <FaRegClipboard />}
            </CopyIcon>
        </CodeBlockWrapper>
    );
};

const CustomMarkdown = ({ content, forLanding }) => {
    content = Array.isArray(content) ? content.map(String).join('') : String(content);
    return (
        <>
            {forLanding && (
                <MarkdownWrapperLanding>
                    <ReactMarkdown
                        remarkPlugins={[remarkGfm]}
                        rehypePlugins={[rehypeRaw, [rehypeAttr, { properties: 'attr' }]]}
                        components={{
                            code: CodeBlock
                        }}
                    >
                        {content}
                    </ReactMarkdown>
                </MarkdownWrapperLanding>
            )}
            {!forLanding && (
                <MarkdownWrapper>
                    <ReactMarkdown
                        remarkPlugins={[remarkGfm]}
                        components={{
                            code: CodeBlock
                        }}
                    >
                        {content}
                    </ReactMarkdown>
                </MarkdownWrapper>
            )}
        </>
    );
};

export default CustomMarkdown;
