// src/components/Message.js

import React from 'react';
import MessageIcon from './MessageIcon';
import TasksInMessage from './Tasks';
import ImageViewer from './ImageViewer';
import { TaskItem, TaskTitle, TaskTitleSubGroup } from './Tasks';
import CustomLoader from './loader/CustomLoader.js';

const Message = ({
    message,
    agent,
    configuration,
    hostname,
    apiKey,
    taskInputValues,
    handleTaskInputChange,
    handleTaskInputSubmit,
    inGroup
}) => {
    const handleBotResponse = (text, additional_data) => {
        if (!text || text.length === 0) return '';
        if (additional_data && additional_data.error) {
            return `<span class="message-error">${text}</span>`;
        }
        const urlRegex = /(\bhttps?:\/\/[^\s()]+\b)/g;
        return text.replace(urlRegex, (url) => `<a href="${url}" rel="noopener noreferrer">${url}</a>`);
    };

    if (message.type === 'tasks') {
        return (
            <div key={message.id} className="chat-message bot">
                {(configuration.logo && !inGroup) && <MessageIcon configuration={configuration} />}
                <div className="bot-container">
                    <div
                        className="message-text-wrapper"
                        style={{
                            backgroundColor: configuration.agent_message_background_color || 'transparent',
                        }}
                    >
                        <div
                            className="message-text"
                            style={{
                                color: configuration.agent_message_text_color || 'black',
                            }}
                        >
                            <TasksInMessage
                                hostname={hostname}
                                apiKey={apiKey}
                                agent={agent}
                                language={configuration.language}
                                taskList={message.data}
                                configuration={configuration}
                                handleTaskInputChange={handleTaskInputChange}
                                handleTaskInputSubmit={handleTaskInputSubmit}
                                taskInputValues={taskInputValues}
                                inGroup={inGroup}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    } else if (message.type === 'placeholder') {
        return (
            <div key={message.id} className="chat-message bot">
                {configuration.logo && <MessageIcon configuration={configuration} />}
                <div className="bot-container">
                    <div
                        className="message-text-wrapper"
                        style={{
                            backgroundColor: configuration.agent_message_background_color || 'transparent',
                        }}
                    >
                        <div
                            className="message-text"
                            style={{
                                color: configuration.agent_message_text_color || 'black',
                                paddingTop: 10,
                                paddingBottom: 10,
                            }}
                        >
                            <TaskItem $isTitle={true}>
                                <TaskTitle>
                                    <TaskTitleSubGroup>
                                        <CustomLoader size={16} style={{ marginRight: '12px' }} color={configuration.agent_message_text_color} />
                                        {message.data}
                                    </TaskTitleSubGroup>
                                </TaskTitle>
                            </TaskItem>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div
                key={message.id}
                className={`chat-message ${message.sender === 'user' ? 'user' : 'bot'}`}
                style={{
                    backgroundColor:
                        message.sender === 'user' && configuration.user_message_background_color
                            ? configuration.user_message_background_color
                            : 'transparent',
                }}
            >
                {message.sender === 'bot' && configuration.logo && (
                    <MessageIcon configuration={configuration} />
                )}
                <div className={message.sender === 'bot' ? 'bot-container' : ''}>
                    <div
                        className="message-text-wrapper"
                        style={{
                            backgroundColor:
                                message.sender === 'bot' && configuration.agent_message_background_color
                                    ? configuration.agent_message_background_color
                                    : 'transparent',
                        }}
                    >
                        <div
                            className="message-text"
                            style={{
                                color:
                                    message.sender === 'bot' && configuration.agent_message_text_color
                                        ? configuration.agent_message_text_color
                                        : message.sender !== 'bot' && configuration.user_message_text_color
                                            ? configuration.user_message_text_color
                                            : 'black',
                            }}
                        >
                            {message.sender === 'user' ? (
                                <>
                                    <span>{message.data}</span>
                                    {message.additional_data?.file && (
                                        <>
                                            {message.additional_data.file.type.startsWith('image') && (
                                                <ImageViewer
                                                    src={message.additional_data.file.content}
                                                    color={configuration.user_message_text_color}
                                                    noDownload={true}
                                                />
                                            )}
                                        </>
                                    )}
                                </>
                            ) : (message.type === 'text' || message.type === 'html') && (
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: handleBotResponse(message.data, message.additional_data),
                                    }}
                                ></span>
                            )}
                            {message.sender === 'bot' && message.type === 'image' && (
                                <ImageViewer
                                    src={message.data}
                                    color={configuration.agent_message_text_color}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default Message;
