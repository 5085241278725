import React, { useState } from 'react';
import styled from 'styled-components';
import CustomMarkdown from './CustomMarkdown.js';
import {
  FaFilePdf,
  FaFileExcel,
  FaFileAudio,
  FaPlay,
  FaFileAlt,
  FaTimes,
} from 'react-icons/fa';
import ReactPlayer from 'react-player';
import GlassEffect from './GlassEffect.js';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
`;

const SectionTitle = styled.h2`
  margin: 16px 0 0;
`;


const DocumentsTitle = styled.h2`
  margin: 16px 0 0;
`;

const MosaicContainer = styled.div`
  max-height: calc((100px + 20px) * 2 + 32px);
  overflow-y: auto;
`;

const Mosaic = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  padding: 16px;
  justify-content: center;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const DocumentCard = styled.div`
  background: #fff;
  border-radius: 8px;
  position: relative;
  transition: box-shadow 0.3s, transform 0.2s;
  cursor: pointer;
  text-align: center;
  color: inherit;

  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80px;
  padding: 10px;

  &:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    transform: translateY(-4px);
  }
`;

const DocumentImage = styled.img`
  max-width: 48px;
  max-height: 48px;
  object-fit: cover;
  border-radius: 4px;
`;

const DocumentIcon = styled.div`
  font-size: 48px;
  color: #555;
  margin-bottom: 8px;
`;

const PlayIconOverlay = styled(FaPlay)`
  position: absolute;
  font-size: 36px;
  color: white;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  padding: 8px;
`;

const DocumentName = styled.span`
  font-size: 14px;
  font-weight: 500;
  margin-top: auto;
  word-break: break-word;
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  position: relative;
  background: #fff;
  padding: 16px;
  border-radius: 8px;
  max-width: 90%;
  max-height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ModalClose = styled(FaTimes)`
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 24px;
  color: #333;
  cursor: pointer;

  &:hover {
    color: #000;
  }
`;

const ModalImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

const InitialView = ({ configuration, handleSendMessage }) => {
  const { markdown, agents, linked_documents = [] } = configuration;
  const [modalContent, setModalContent] = useState(null);

  const openModal = (content) => setModalContent(content);
  const closeModal = () => setModalContent(null);

  const getFileTypeIcon = (url) => {
    if (url.match(/\.pdf$/i)) return <FaFilePdf />;
    if (url.match(/\.(xls|xlsx)$/i)) return <FaFileExcel />;
    if (url.match(/\.(mp3|wav|ogg)$/i)) return <FaFileAudio />;
    return <FaFileAlt />;
  };

  return (
    <Container>
      {linked_documents.length > 0 && (
        <>
          <DocumentsTitle>Documents</DocumentsTitle>
          <MosaicContainer>
            <Mosaic>
              {linked_documents.map((doc, index) => (
                <DocumentCard
                  key={index}
                  onClick={() => {
                    if (doc.url.match(/\.(jpeg|jpg|gif|png|svg)$/i)) {
                      openModal(<ModalImage src={doc.url} alt={doc.name} />);
                    } else if (ReactPlayer.canPlay(doc.url)) {
                      openModal(<ReactPlayer url={doc.url} controls />);
                    } else {
                      window.open(doc.url, '_blank');
                    }
                  }}
                >
                  {doc.url.match(/\.(jpeg|jpg|gif|png|svg)$/i) ? (
                    <DocumentImage src={doc.url} alt={doc.name} />
                  ) : ReactPlayer.canPlay(doc.url) ? (
                    <>
                      <DocumentIcon>{getFileTypeIcon(doc.url)}</DocumentIcon>
                      <PlayIconOverlay />
                    </>
                  ) : (
                    <DocumentIcon>{getFileTypeIcon(doc.url)}</DocumentIcon>
                  )}
                  <DocumentName>{doc.name}</DocumentName>
                </DocumentCard>
              ))}
            </Mosaic>
          </MosaicContainer>
          {modalContent && (
            <Modal onClick={closeModal}>
              <ModalContent onClick={(e) => e.stopPropagation()}>
                <ModalClose onClick={closeModal} />
                {modalContent}
              </ModalContent>
            </Modal>
          )}
        </>
      )}
      {Object.keys(agents).length > 0 && (
        <>
          <SectionTitle>Agents</SectionTitle>
          <GlassEffect agents={Object.values(agents)} />
        </>
      )}
      <CustomMarkdown content={markdown} forLanding={true} />
    </Container>
  );
};

export default InitialView;
