// src/components/Footer.js

import React from 'react';
import StyledTextarea from './StyledTextarea';
import StyledButton from './StyledButton';
import SendIcon from '@mui/icons-material/Send';
import MicIcon from '@mui/icons-material/Mic';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import BuddELong from './BuddELong';
import { hexToRGBA, getFileType, isImage } from '../utils';

import ImageIcon from '@mui/icons-material/Image';
import AudioIcon from '@mui/icons-material/Audiotrack';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import FileIcon from '@mui/icons-material/InsertDriveFile';

const Footer = ({
    isInputDisabled,
    inputMessage,
    handleInputChange,
    handleKeyPress,
    configuration,
    handleInputFocus,
    isUploadingFile,
    selectedFile,
    handleFileChange,
    handleAudioClick,
    handleSendMessage,
    browserSupportsSpeechRecognition,
    listening,
    isSmartphone,
    inputMessageRef,
    setSelectedFile,
}) => {

    const translation = {
        "type_message": {
            "en": "Write a message",
            "fr": "Écrire un message"
        }
    }

    const FileIconComponent = (extension) => {
        const type = getFileType(extension);
        switch (type) {
            case 'image':
                return <ImageIcon />;
            case 'audio':
                return <AudioIcon />;
            case 'pdf':
                return <PdfIcon />;
            default:
                return <FileIcon />;
        }
    };

    return (
        <div
            className="footer-wrapper"
            style={{
                backgroundColor: configuration.background_color || 'transparent',
            }}
        >
            <div className={`chat-form ${isInputDisabled ? 'disabled' : ''}`}>
                <div className="chat-input-container">
                    {configuration.allow_import_file && !selectedFile && !isUploadingFile && (
                        <div
                            className="input-options"
                            style={{
                                position: 'absolute',
                                right: '55px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '1px',
                            }}
                        >
                            {isSmartphone() && !inputMessage && (
                                <label
                                    className={`upload-file-button ${isInputDisabled ? 'disabled' : ''}`}
                                    style={{
                                        cursor: selectedFile || isUploadingFile ? 'initial' : 'pointer',
                                        color: configuration.text_color || 'black',
                                        opacity: isInputDisabled || selectedFile || isUploadingFile ? 0.2 : 1,
                                        transition: 'opacity 0.3s ease',
                                        height: '25px',
                                        width: '25px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        borderRadius: '5px',
                                    }}
                                >
                                    <input
                                        type="file"
                                        accept="image/*"
                                        capture="environment"
                                        onChange={handleFileChange}
                                        style={{ display: 'none' }}
                                        disabled={isInputDisabled || selectedFile || isUploadingFile}
                                    />
                                    <CameraAltIcon style={{ height: '70%', width: '70%' }} />
                                </label>
                            )}

                            <label
                                className={`upload-file-button ${isInputDisabled ? 'disabled' : ''}`}
                                style={{
                                    cursor: selectedFile || isUploadingFile ? 'initial' : 'pointer',
                                    color: configuration.text_color || 'black',
                                    opacity: isInputDisabled || selectedFile || isUploadingFile ? 0.2 : 1,
                                    transition: 'opacity 0.3s ease',
                                    height: '25px',
                                    width: '25px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: '5px',
                                }}
                            >
                                <input
                                    type="file"
                                    onChange={handleFileChange}
                                    style={{ display: 'none' }}
                                    disabled={isInputDisabled || selectedFile || isUploadingFile}
                                />
                                <AttachFileIcon
                                    style={{
                                        height: '70%',
                                        width: '70%',
                                        transform: 'rotate(45deg)',
                                    }}
                                />
                            </label>
                        </div>
                    )}
                    <StyledTextarea
                        ref={inputMessageRef}
                        className="chat-input"
                        value={inputMessage}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyPress}
                        placeholder={translation.type_message[configuration.language]}
                        disabled={isInputDisabled}
                        onFocus={handleInputFocus}
                        $textColor={configuration.text_color}
                        style={{
                            border: `solid 1px ${hexToRGBA(configuration.text_color || '#000000', 0.4)}`,
                            paddingRight:
                                configuration.allow_import_file && !selectedFile && !isUploadingFile
                                    ? '35px'
                                    : null,
                        }}
                        placeholderColor={configuration.text_color}
                    />
                    {isUploadingFile && (
                        <div
                            className="loading-indicator"
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: '#f0f2f5',
                                borderRadius: '10px',
                                width: '75px',
                                height: '75px',
                                margin: 'auto',
                            }}
                        >
                            <CircularProgress />
                        </div>
                    )}

                    {selectedFile && (
                        <div
                            className="file-indicator"
                            style={{
                                position: 'relative',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: '#fff',
                                borderRadius: '10px',
                                width: '75px',
                                height: '75px',
                                boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
                            }}
                        >
                            {isImage(selectedFile.extension) ? (
                                <img
                                    src={selectedFile.url}
                                    alt="Uploaded"
                                    style={{
                                        maxWidth: '100%',
                                        maxHeight: '100%',
                                        borderRadius: '10px',
                                    }}
                                />
                            ) : (
                                FileIconComponent(selectedFile.extension)
                            )}
                            <button
                                onClick={() => setSelectedFile(null)}
                                style={{
                                    position: 'absolute',
                                    padding: '0',
                                    top: '4px',
                                    right: '4px',
                                    backgroundColor: configuration.background_color || 'white',
                                    color: configuration.text_color || 'black',
                                    borderRadius: '50%',
                                    border: 'none',
                                    cursor: 'pointer',
                                    width: '25px',
                                    height: '25px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <CloseIcon style={{ fontSize: '20px' }} />
                            </button>
                        </div>
                    )}
                    {browserSupportsSpeechRecognition &&
                        !((inputMessage || selectedFile || isUploadingFile) && !listening) && (
                            <StyledButton
                                className={`send-message-button ${isInputDisabled ? 'disabled' : ''}`}
                                disabled={isInputDisabled}
                                style={{
                                    color: configuration.background_color || 'white',
                                    backgroundColor: configuration.text_color || 'black',
                                    opacity: isInputDisabled ? 0.2 : 1,
                                    transition: 'opacity 0.3s ease',
                                    visibility:
                                        (inputMessage || selectedFile || isUploadingFile) && !listening
                                            ? 'hidden'
                                            : 'visible',
                                }}
                                onClick={handleAudioClick}
                            >
                                <MicIcon
                                    style={{
                                        height: '70%',
                                        width: '70%',
                                        animation: listening ? 'pulse 1s infinite' : 'none',
                                    }}
                                />
                            </StyledButton>
                        )}
                    {(!browserSupportsSpeechRecognition ||
                        !((!listening && !inputMessage && !selectedFile && !isUploadingFile) || listening)) && (
                            <button
                                className={`send-message-button ${isInputDisabled || (!inputMessage && !selectedFile && !isUploadingFile)
                                    ? 'disabled'
                                    : ''
                                    }`}
                                onClick={handleSendMessage}
                                disabled={isInputDisabled || (!inputMessage && !selectedFile && !isUploadingFile)}
                                style={{
                                    color: configuration.background_color || 'white',
                                    backgroundColor: configuration.text_color || 'black',
                                    opacity:
                                        isInputDisabled || (!inputMessage && !selectedFile && !isUploadingFile)
                                            ? 0.2
                                            : 1,
                                    transition: 'opacity 0.3s ease',
                                    visibility:
                                        (!listening && !inputMessage && !selectedFile && !isUploadingFile) || listening
                                            ? 'hidden'
                                            : 'visible',
                                }}
                            >
                                <SendIcon style={{ height: '60%', width: '60%' }} />
                            </button>
                        )}
                </div>
            </div>

            <div className="budd-e-footer">
                {!configuration.white_label && (
                    <div className="budd-e-label">
                        <span
                            style={{
                                color: configuration.text_color
                                    ? hexToRGBA(configuration.text_color, 0.7)
                                    : 'grey',
                            }}
                        >
                            Powered by
                        </span>
                        <BuddELong className="white-label-logo" fillColor={configuration.text_color} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Footer;
