// src/components/Header.js

import React from 'react';
import styled from 'styled-components';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { styled as muiStyled } from '@mui/material/styles';


const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  color: black;
  background-color: ${(props) => props.$backgroundColor || 'transparent'};
  height: 50px;
  align-items: center;
  padding: 10px;
  transition: all 0.5s ease;
  position: relative;
`;

const ChatTitle = styled.div`
  display: flex;
  flex-grow: 1;
  height: 100%;
  align-items: center;
  flex-direction:  row;
  height: 50px;
  gap: 0;
  justify-content: flex-start;
`;

const StyledIcon = (IconComponent) => muiStyled(IconComponent)`
  cursor: pointer;
  padding: 0px;
  margin: 0px;
  color: ${(props) => props.iconcolor || '#4b4b4b'};
  align-self: flex-start;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  margin-left: 10px;
`;

const ChatLogo = styled.img`
  max-height: 40px;
  max-width: 100px;
  transition: all 0.5s ease;
`;

const TitleTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items:  flex-start;
  text-align: left;
`;

const TitleSpan = styled.span`
  color: ${(props) => props.$textColor || 'black'};
  font-family: 'system-ui';
  font-size: 1.2em
`;

const HeaderIconsContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 10px;
  top: 10px;
`;

const ChevronLeftIconStyled = StyledIcon(ChevronLeftIcon);
const ChevronRightIconStyled = StyledIcon(ChevronRightIcon);

const Header = ({
    showWelcomeMessage,
    configuration,
    setShowWelcomeMessage,
    messages,
}) => {
    return (
        <HeaderContainer
            $showWelcomeMessage={showWelcomeMessage}
            $backgroundColor={configuration.background_color}
        >
            <ChatTitle $showWelcomeMessage={showWelcomeMessage}>
                <TitleContainer
                    $showWelcomeMessage={showWelcomeMessage}
                >
                    {configuration.logo && (
                        <ChatLogo
                            src={configuration.logo}
                            alt="budd-e"
                            $showWelcomeMessage={showWelcomeMessage}
                        />
                    )}
                    <TitleTextContainer $showWelcomeMessage={showWelcomeMessage}>
                        <TitleSpan
                            $textColor={configuration.text_color}
                            $isWelcome={showWelcomeMessage}
                        >
                            {configuration.display_name}
                        </TitleSpan>
                        {showWelcomeMessage && configuration.welcome && (
                            <TitleSpan
                                $textColor={configuration.text_color}
                                $isWelcome={showWelcomeMessage}
                            >
                                {configuration.welcome}
                            </TitleSpan>
                        )}
                    </TitleTextContainer>
                </TitleContainer>
            </ChatTitle>

            <HeaderIconsContainer>
                {!showWelcomeMessage && (
                    <ChevronLeftIconStyled
                        iconcolor={configuration.text_color || '#4b4b4b'}
                        onClick={() => {
                            setShowWelcomeMessage(true);
                        }}
                    />
                )}
                {showWelcomeMessage && messages && messages.length > 0 && (
                    <ChevronRightIconStyled
                        iconcolor={configuration.text_color || '#4b4b4b'}
                        onClick={() => {
                            setShowWelcomeMessage(false);
                        }}
                    />
                )}
            </HeaderIconsContainer>
        </HeaderContainer>
    );
};

export default Header;
