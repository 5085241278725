import React, { useState } from 'react';
import DownloadIcon from '@mui/icons-material/Download';

const ImageViewer = ({ src, alt, color, noDownload = false }) => {

    const [isEnlarged, setIsEnlarged] = useState(false);
    const handleImageClick = () => {
        setIsEnlarged(true);
    };

    const handleClose = () => {
        setIsEnlarged(false);
    };

    return (
        <div className="card">
            <img
                src={src}
                alt={alt ? alt : ""}
                onClick={handleImageClick}
                style={{ cursor: 'pointer', maxWidth: "100%", borderRadius: "20px" }}
            />
            {!noDownload && (
                <a
                    href={src}
                    download
                    target="_blank"
                    rel="noreferrer"
                    style={{
                        marginTop: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "right",
                        color: color
                    }}
                >
                    <DownloadIcon />
                </a>
            )}
            {isEnlarged && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    borderRadius: "20px",
                    width: '100%',
                    height: '100%',
                    background: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 9999999
                }} onClick={handleClose}>
                    <img
                        src={src}
                        alt={alt ? alt : ""}
                        style={{
                            maxHeight: '90%',
                            maxWidth: '90%'
                        }}
                    />
                </div>
            )}
        </div>
    );
}

export default ImageViewer
