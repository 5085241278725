// src/pages/ChatPage.js

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import '../css/Page.css';
import '../css/Chat.css';
import '../css/Footer.css';
import Chat from '../components/Chat';
import loadConfig from '../config/loadConfig';

const ChatPage = ({ hostname }) => {
    const { apiKey } = useParams();
    const [config, setConfig] = useState(null);
    const [sessionStorageAvailable, setSessionStorageAvailable] = useState(true);

    useEffect(() => {
        async function fetchConfig() {
            const configData = await loadConfig(hostname, apiKey);
            setConfig(configData);
        }
        fetchConfig();
    }, [apiKey, hostname]);

    useEffect(() => {
        const testSessionStorage = () => {
            try {
                sessionStorage.setItem('test', 'test');
                sessionStorage.removeItem('test');
                return true;
            } catch {
                return false;
            }
        };
        setSessionStorageAvailable(testSessionStorage());
    }, []);

    if (!config) return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
            <CircularProgress />
        </Box>
    )

    return (
        <div id='main'>
            <Chat
                className='chat-container'
                apiKey={apiKey}
                hostname={hostname}
                sessionStorageAvailable={sessionStorageAvailable}
                configuration={config}
            />
        </div>
    );
};

export default ChatPage;
