import React, { useState, useEffect } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import styled from 'styled-components';
import ImageViewer from './ImageViewer.js';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CustomMarkdown from './CustomMarkdown.js';
import { FaTrashAlt } from 'react-icons/fa';
import CustomLoader from './loader/CustomLoader.js';

const getTaskColors = (state) => {
    switch (state) {
        case 'pending':
            return { borderColor: '#a0a0a0', backgroundColor: '#f0f0f0' };
        case 'running':
            return { borderColor: '#007bff', backgroundColor: '#e6f0ff' };
        case 'finished':
            return { borderColor: '#28a745', backgroundColor: '#e6ffe6' };
        case 'error':
            return { borderColor: '#dc3545', backgroundColor: '#ffe6e6' };
        case 'missing_inputs':
            return { borderColor: '#fd7e14', backgroundColor: '#fff5e6' };
        default:
            return { borderColor: '#a0a0a0', backgroundColor: '#f0f0f0' };
    }
};

const TaskContainer = styled.div`
  margin:  ${(props) => (props.$inGroup ? '0px' : '10px 0')};
`;

const TaskSeparator = styled.hr`
  border: none;
  margin: 0;
`;

export const TaskItem = styled.div`
  color: ${(props) => props.$textColor};
  padding: ${(props) => (props.$isTitle ? '0px 5px' : '0px 5px')};
  position: relative;
  margin-left: ${(props) => (props.$isTitle ? '60px' : '0px')};
  line-height: 30px;
  transition: all 0.3s ease;
`;

export const TaskTitle = styled.div`
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8em;
  color: ${(props) => props.$textColor};
`;

export const TaskTitleSubGroup = styled.div`
  align-items: center;
  display:flex;
`;


const SectionContainer = styled.div`
  margin-top: 10px;
  padding: 8px;
  border: 1px solid #eee;
  border-radius: 5px;
  h1 {
    font-size: 1.2em;
  }
  h2 {
    font-size: 1.1em;
  }
`;

const SectionTitle = styled.h4`
  margin: 0 0 10px 0;
  padding-bottom: 5px;
  border-bottom: 1px solid #ddd;
  font-size: 1em;
  color: ${(props) => props.$textColor || '#333'};
`;

const InputList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 5px 0;
`;

const InputListItem = styled.li`
  margin-bottom: 5px;
`;

const StyledFormContainer = styled.div`
  margin-bottom: 15px;
`;

const StyledLabel = styled.label`
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-bottom: 15px;
  box-sizing: border-box;
`;

const StyledSelect = styled.select`
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-bottom: 15px;
  box-sizing: border-box;
`;

const StyledCheckbox = styled.input`
  margin-right: 10px;
`;

const StyledRadio = styled.input`
  margin-right: 10px;
`;

const StyledButton = styled.button`
  padding: 10px 20px;
  background-color: ${(props) => props.$backgroundColor};
  color:  ${(props) => props.$textColor || '#333'};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;

const TasksQuoteBlock = styled.div`
  padding-left:  ${(props) => (props.$inGroup ? '0px' : '10px')};
  margin-bottom: ${(props) => (props.$inGroup ? '0px' : '20px')};
  margin-top:  ${(props) => (props.$inGroup ? '0px' : '20px')};
  margin-left:  ${(props) => (props.$inGroup ? '0px' : '10px')};
`;

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const ActionButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  color: ${(props) =>
        props.$active ? props.$activeColor || '#007bff' : props.$textColor || '#333'};
  &:hover {
    color: ${(props) => props.$hoverColor || '#007bff'};
    background-color: initial;
  }
  &:not(:last-child) {
    margin-right: 5px;
  }

  svg {
    font-size: 18px;
  }
`;

const translation = {
    "output": {
        "en": "Output",
        "fr": "Sortie"
    },
    "inputs": {
        "en": "Inputs",
        "fr": "Entrées"
    },
    "missing_inputs": {
        "en": "Provide missing inputs",
        "fr": "Fournir les entrées manquantes"
    },
    "submit": {
        "en": "Submit",
        "fr": "Soumettre"
    },
    "upload_image": {
        "en": "Click or drag an image here to upload",
        "fr": "Cliquez ou glissez une image ici pour télécharger"
    },
    "uploading": {
        "en": "Uploading...",
        "fr": "Téléchargement en cours..."
    },
    "remove_image": {
        "en": "Remove image",
        "fr": "Supprimer l'image"
    }
};

// Container pour chaque ligne de tâche, incluant le cercle et les lignes.
const TaskLineWrapper = styled.div`
  position: relative;
  padding-left: 40px;
  margin: 0px 0;

  /* Ligne verticale parent -> enfant */
  &:before {
    content: '';
    position: absolute;
    left: 19px;
    top: ${(props) => (props.$isFirstChild ? '5px' : '0px')};
    bottom: ${(props) => (props.$isLastChild ? '5px' : '0px')};
    width: 2px;
    background: ${(props) => props.$borderColor || '#ccc'};
  }
  
`;

const TaskNumberCircle = styled.div`
  position: absolute;
  top: 5px;
  left: 20px;
  transform: translate(-50%, 0);
  background: #fff;
  border: 2px solid ${(props) => props.$borderColor || '#ccc'};
  border-radius: 50%;
  width: 16px;
  height: 16px;
  font-size: 10px;
  display: flex;
  align-items: baseline;
  justify-content: center;
  font-weight: bold;
  color: #333;

  /* Ligne horizontale pour connecter la ligne verticale au cercle (angle droit) si depth > 0 */
  &:after {
    content: '';
    position: absolute;
    left: 0px;
    top: 50%;
    width: 33px;
    height: 2px;
    background: ${(props) => props.$borderColor || '#ccc'};
    transform: translateX(-100%);
  }

  ${(props) => (props.$depth === 0 || !props.$isFirstChild) && `
    &:after {
      display: none;
    }
  `}
`;


const TasksInMessage = ({
    language,
    hostname,
    apiKey,
    taskList,
    configuration,
    handleTaskInputChange,
    handleTaskInputSubmit,
    taskInputValues,
    inGroup,
    agent
}) => {
    const [selectedFiles, setSelectedFiles] = useState({});
    const [isUploadingFile, setIsUploadingFile] = useState(false);
    const [taskTree, setTaskTree] = useState([]);
    const [expandedTaskIds, setExpandedTaskIds] = useState({});
    const [globalIndices, setGlobalIndices] = useState({});

    useEffect(() => {
        const roots = buildTaskTree(taskList);
        setTaskTree(roots);
    }, [taskList]);

    // Assigner un index global à chaque tâche (ordre d'apparition)
    useEffect(() => {
        if (taskTree.length > 0) {
            const map = {};
            let counter = 1;
            const assignIndices = (tasks) => {
                for (const t of tasks) {
                    map[t.id] = counter++;
                    if (t.children && t.children.length > 0) {
                        assignIndices(t.children);
                    }
                }
            };
            assignIndices(taskTree);
            setGlobalIndices(map);
        }
    }, [taskTree]);

    useEffect(() => {
        const missingInputTasks = taskList.filter(task => task.state === 'missing_inputs');
        if (missingInputTasks.length > 0) {
            setExpandedTaskIds(prev => {
                const newState = { ...prev };
                missingInputTasks.forEach(t => { newState[t.id] = true; });
                return newState;
            });
        }
    }, [taskList]);

    const toggleTaskExpansion = (taskId) => {
        setExpandedTaskIds((prev) => ({
            ...prev,
            [taskId]: !prev[taskId],
        }));
    };

    const [reactions, setReactions] = useState({});
    const [copyStatus, setCopyStatus] = useState('idle');

    const handleLike = (taskId) => {
        setReactions((prev) => ({
            ...prev,
            [taskId]: prev[taskId] === 'like' ? null : 'like',
        }));
    };

    const handleDislike = (taskId) => {
        setReactions((prev) => ({
            ...prev,
            [taskId]: prev[taskId] === 'dislike' ? null : 'dislike',
        }));
    };

    const handleCopy = (response) => {
        let textToCopy = '';

        switch (response.type) {
            case 'text':
                textToCopy = response.content;
                break;
            case 'html':
                textToCopy = response.content;
                break;
            default:
                textToCopy = '';
        }

        if (!navigator.clipboard) {
            console.error('L\'API Clipboard n\'est pas disponible');
            return;
        }

        navigator.clipboard.writeText(textToCopy).then(
            () => {
                setCopyStatus('copied');
                setTimeout(() => {
                    setCopyStatus('idle');
                }, 2000);
            },
            (err) => {
            }
        );
    };

    const handleFileChange = async (e, taskId) => {
        const { name, files } = e.target;
        const file = files[0];
        setIsUploadingFile(true);
        const formData = new FormData();
        formData.append('file', file);
        try {
            const response = await fetch(`${hostname}/api/upload_file?api_key=${apiKey}`, {
                method: 'POST',
                body: formData,
            });
            if (response.ok) {
                const data = await response.json();
                handleTaskInputChange({ target: { name, value: data.url } }, taskId)
                setSelectedFiles((prev) => ({
                    ...prev,
                    [taskId]: data,
                }));
            }
        } catch (error) {
            console.error('Error:', error);
        }
        e.target.value = '';
        setIsUploadingFile(false);
    };

    const handleDeleteFile = (taskId) => {
        setSelectedFiles((prev) => {
            const newFiles = { ...prev };
            delete newFiles[taskId];
            return newFiles;
        });
    };

    const buildTaskTree = (tasks) => {
        const taskMap = {};
        const roots = [];

        // Créer une map des tâches par ID et initialiser les enfants
        tasks.forEach(task => {
            task.children = [];
            taskMap[task.id] = task;
        });

        // Attacher les tâches à leurs parents
        tasks.forEach(task => {
            if (task.parent) {
                const parentTask = taskMap[task.parent];
                if (parentTask) {
                    parentTask.children.push(task);
                } else {
                    roots.push(task);
                }
            } else {
                roots.push(task);
            }
        });

        return roots;
    };

    const renderTaskResponse = (response, taskId) => {
        return (
            <div>
                {(() => {
                    switch (response.type) {
                        case 'html':
                            return response.error ? (
                                <div style={{ color: 'red' }}>
                                    {response.error}
                                </div>
                            ) : (
                                <div dangerouslySetInnerHTML={{ __html: response.content }}></div>
                            );
                        case 'image':
                            return response.error ? (
                                <div style={{ color: 'red' }}>
                                    {response.error}
                                </div>
                            ) : (
                                <ImageViewer
                                    src={response.content}
                                    color={configuration.agent_message_text_color}
                                />
                            );
                        default:
                            const textContent = response.error
                                ? `${response.error}`
                                : typeof response.content === 'object'
                                    ? '```json\n' + JSON.stringify(response.content, null, 2) + '\n```'
                                    : response.content;
                            return (
                                <div style={{ color: configuration.agent_message_text_color || 'black' }}>
                                    <CustomMarkdown content={textContent} />
                                </div>
                            );
                    }

                })()}
                <ActionsContainer>
                    <ActionButton
                        onClick={() => handleLike(taskId)}
                        $textColor={configuration.agent_message_text_color}
                        $hoverColor={configuration.agent_message_text_hover_color}
                        $active={reactions[taskId] === 'like'}
                        $activeColor="#007bff"
                        aria-label="Like"
                    >
                        <ThumbUpIcon />
                    </ActionButton>
                    <ActionButton
                        onClick={() => handleDislike(taskId)}
                        $textColor={configuration.agent_message_text_color}
                        $hoverColor={configuration.agent_message_text_hover_color}
                        $active={reactions[taskId] === 'dislike'}
                        $activeColor="#007bff"
                        aria-label="Dislike"
                    >
                        <ThumbDownIcon />
                    </ActionButton>
                    <ActionButton
                        onClick={() => handleCopy(response)}
                        $textColor={configuration.agent_message_text_color}
                        $hoverColor={configuration.agent_message_text_hover_color}
                        aria-label="Copier"
                    >
                        {copyStatus === 'copied' ? (
                            <CheckCircleIcon />
                        ) : (
                            <ContentCopyIcon />
                        )}
                    </ActionButton>
                </ActionsContainer>
            </div>
        );
    };


    const renderTaskItem = (task, depth, isLastChild, isFirstChild) => {
        const { borderColor, backgroundColor } = getTaskColors(task.state);
        const isExpanded = expandedTaskIds[task.id] || false;
        const taskNumber = globalIndices[task.id];

        const handleTitleClick = () => {
            toggleTaskExpansion(task.id);
        };

        return (
            <TaskLineWrapper $depth={depth} $borderColor={borderColor} $isLastChild={isLastChild} $isFirstChild={isFirstChild}>
                <TaskNumberCircle $depth={depth} $borderColor={borderColor} $isFirstChild={isFirstChild}>{taskNumber}</TaskNumberCircle>
                <TaskItem
                    key={task.id}
                    $borderColor={borderColor}
                    $backgroundColor={backgroundColor}
                    $textColor={configuration.agent_message_text_color}
                    style={{ position: 'relative' }}
                >
                    <TaskTitle onClick={handleTitleClick}>
                        <TaskTitleSubGroup>
                            {(task.state === 'running') && (
                                <CustomLoader size={16} style={{ marginRight: '12px' }} color={configuration.agent_message_text_color} />
                            )}
                            {task.title}
                        </TaskTitleSubGroup>
                        {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </TaskTitle>
                    {isExpanded && (
                        <>
                            {(task.inputs && task.state !== 'missing_inputs') && (
                                <SectionContainer>
                                    <SectionTitle $textColor={configuration.agent_message_text_color}>{translation["inputs"][language]}</SectionTitle>
                                    <InputList>
                                        {Object.entries(task.inputs).map(([key, value]) => (
                                            <InputListItem key={key}>
                                                <strong>{key}:</strong> {typeof value === 'object' ? JSON.stringify(value, null, 2) : String(value)}
                                            </InputListItem>
                                        ))}
                                    </InputList>
                                </SectionContainer>
                            )}
                            {(task.response && task.state !== 'missing_inputs') && (
                                <SectionContainer>
                                    <SectionTitle $textColor={configuration.agent_message_text_color}>{translation["output"][language]}</SectionTitle>
                                    {renderTaskResponse(task.response, task.id)}
                                </SectionContainer>
                            )}
                            {task.state === 'missing_inputs' && task.missing_inputs && (
                                <SectionContainer>
                                    <SectionTitle $textColor={configuration.agent_message_text_color}>{translation["missing_inputs"][language]}</SectionTitle>
                                    <form onSubmit={(e) => {
                                        e.preventDefault();
                                        handleTaskInputSubmit(e, task.id, selectedFiles[task.id]?.url, agent);
                                    }}>
                                        {task.missing_inputs.map((input, index) => (
                                            <StyledFormContainer key={index}>
                                                <StyledLabel>{input.description}</StyledLabel>
                                                {input.type === 'select' && (
                                                    <StyledSelect
                                                        name={input.name}
                                                        value={
                                                            (taskInputValues[task.id] &&
                                                                taskInputValues[task.id][input.name]) ||
                                                            ''
                                                        }
                                                        onChange={(e) => handleTaskInputChange(e, task.id)}
                                                    >
                                                        {input.options.map((option, idx) => (
                                                            <option key={idx} value={option.value}>
                                                                {option.label}
                                                            </option>
                                                        ))}
                                                    </StyledSelect>
                                                )}
                                                {input.type === 'checkbox' && (
                                                    <div>
                                                        <StyledCheckbox
                                                            type="checkbox"
                                                            name={input.name}
                                                            checked={
                                                                (taskInputValues[task.id] &&
                                                                    taskInputValues[task.id][input.name]) ||
                                                                false
                                                            }
                                                            onChange={(e) => handleTaskInputChange(e, task.id)}
                                                        />
                                                    </div>
                                                )}
                                                {input.type === 'radio' && (
                                                    <div>
                                                        {input.options.map((option, idx) => (
                                                            <div key={idx}>
                                                                <StyledRadio
                                                                    type="radio"
                                                                    name={input.name}
                                                                    value={option.value}
                                                                    checked={
                                                                        taskInputValues[task.id] &&
                                                                        taskInputValues[task.id][input.name] === option.value
                                                                    }
                                                                    onChange={(e) => handleTaskInputChange(e, task.id)}
                                                                />
                                                                {option.label}
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                                {input.type === 'image' && (
                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
                                                        {!selectedFiles[task.id] ? (
                                                            <>
                                                                <div
                                                                    style={{
                                                                        border: '2px dashed #ccc',
                                                                        borderRadius: '8px',
                                                                        padding: '20px',
                                                                        textAlign: 'center',
                                                                        cursor: 'pointer',
                                                                        width: '200px',
                                                                        transition: 'border-color 0.3s',
                                                                    }}
                                                                    onClick={() => document.querySelector(`input[name="${input.name}"]`).click()}
                                                                    onDragOver={(e) => e.preventDefault()}
                                                                    onDrop={(e) => {
                                                                        e.preventDefault();
                                                                        handleFileChange({ target: { files: e.dataTransfer.files } }, task.id);
                                                                    }}
                                                                >
                                                                    <input
                                                                        type="file"
                                                                        accept="image/*"
                                                                        name={input.name}
                                                                        onChange={(e) => handleFileChange(e, task.id)}
                                                                        disabled={isUploadingFile}
                                                                        style={{ display: 'none' }}
                                                                    />
                                                                    <p style={{ color: '#888' }}>
                                                                        {translation["upload_image"][language]}
                                                                    </p>
                                                                </div>
                                                                {isUploadingFile && <p style={{ color: '#888' }}>{translation["uploading"][language]}</p>}
                                                            </>
                                                        ) : (
                                                            <div style={{ position: 'relative', display: 'inline-block' }}>
                                                                <img
                                                                    src={selectedFiles[task.id].url}
                                                                    alt={translation["output"][language]}
                                                                    style={{
                                                                        maxWidth: '100%',
                                                                        maxHeight: '100%',
                                                                        borderRadius: '10px',
                                                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                                                        border: '1px solid #ddd',
                                                                    }}
                                                                />
                                                                <FaTrashAlt
                                                                    onClick={() => handleDeleteFile(task.id)}
                                                                    style={{
                                                                        position: 'absolute',
                                                                        top: '10px',
                                                                        right: '10px',
                                                                        cursor: 'pointer',
                                                                        color: 'red',
                                                                        backgroundColor: 'white',
                                                                        borderRadius: '50%',
                                                                        padding: '5px',
                                                                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
                                                                    }}
                                                                    title={translation["remove_image"][language]}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                                {input.type !== 'select' && input.type !== 'checkbox' && input.type !== 'radio' && input.type !== 'image' && (
                                                    <StyledInput
                                                        type={input.type === 'integer' || input.type === 'number' ? 'number' : 'text'}
                                                        name={input.name}
                                                        value={
                                                            (taskInputValues[task.id] &&
                                                                taskInputValues[task.id][input.name]) ||
                                                            ''
                                                        }
                                                        onChange={(e) => handleTaskInputChange(e, task.id)}
                                                    />
                                                )}
                                            </StyledFormContainer>
                                        ))}
                                        <StyledButton
                                            $textColor={configuration.agent_message_background_color}
                                            $backgroundColor={configuration.agent_message_text_color}
                                            type="submit">
                                            {translation["submit"][language]}
                                        </StyledButton>
                                    </form>
                                </SectionContainer>
                            )}
                        </>
                    )}
                </TaskItem>
                {task.children && task.children.length > 0 && (
                    <div style={{ marginLeft: '0px' }}>
                        {task.children.map((subTask, index) => (
                            <React.Fragment key={subTask.id}>
                                {renderTaskItem(
                                    subTask,
                                    depth + 1,
                                    index === task.children.length - 1,
                                    index === 0
                                )}
                                {index < task.children.length - 1 && <TaskSeparator />}
                            </React.Fragment>
                        ))}
                    </div>
                )}
            </TaskLineWrapper>
        );
    };

    return (
        <TaskContainer $inGroup={inGroup}>
            {/* {currentTask && (
                <TaskItem
                    key={currentTask.id}
                    $borderColor={getTaskColors(currentTask.state).borderColor}
                    $backgroundColor={'transparent'}
                    $isTitle={true}
                    $textColor={configuration.agent_message_text_color}
                >
                    <TaskTitle>
                        <TaskTitleSubGroup>
                            {(currentTask.state === 'running') && (
                                <CustomLoader size={12} style={{ marginRight: '12px' }} />
                            )}
                            {currentTask.title}
                        </TaskTitleSubGroup>
                    </TaskTitle>
                </TaskItem>
            )} */}
            <TasksQuoteBlock $inGroup={inGroup}>
                {taskTree.map((task, index) => (
                    <React.Fragment key={task.id}>
                        {renderTaskItem(task, 0, index === taskTree.length - 1, index === 0)}
                        {index < taskTree.length - 1 && <TaskSeparator />}

                    </React.Fragment>
                ))}
            </TasksQuoteBlock>

            {/* {lastTask.response && (
                <TaskResponse>
                    {renderTaskResponse(lastTask.response, lastTask.id)}
                </TaskResponse>
            )} */}
        </TaskContainer>
    );
};

export default TasksInMessage;
