// src/components/StyledTextarea.js
import React from 'react';
import AutosizeTextarea from 'react-autosize-textarea';
import styled from 'styled-components';
import { hexToRGBA } from '../utils.js';


const IntermediateTextarea = React.forwardRef(({ textColor, placeholderColor, ...otherProps }, ref) => (
  <AutosizeTextarea ref={ref} {...otherProps} />
));

const StyledTextarea = styled(IntermediateTextarea)`
  color: ${props => props.$textColor ? props.$textColor : "black"};
  
  #chat-bundle-widget #chat-container &.chat-input::placeholder {
    color: ${props => props.placeholderColor ? hexToRGBA(props.placeholderColor, 0.6) : "#6d6d6d"};
  }
`;


export default StyledTextarea;