import React, { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const Avatar = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #f0f0f0;
  border: 2px solid white;
  position: relative;
  margin-left: -20px;
  cursor: pointer;
  overflow: hidden;

  &:first-child {
    margin-left: 0;
  }

  &::after {
    content: '';
    background-image: url(${(props) => props.$image || ""});
    background-size: 75%;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 10%;
    left: 10%;
    width: 75%;
    height: 75%;
    border-radius: 50%;
  }
`;

const Card = styled.div`
  position: absolute;
  top: 60px;
  left: 0;
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 200px;
  padding: 16px;
  z-index: 10;
  display: ${(props) => (props.$show ? "block" : "none")};
`;

const CardTitle = styled.h4`
  margin: 0 0 8px 0;
  font-size: 16px;
  color: #333;
`;

const CardDescription = styled.p`
  margin: 0;
  font-size: 14px;
  color: #555;
`;

const GlassEffect = ({ agents }) => {
    const [hoveredAgent, setHoveredAgent] = useState(null);


    return (
        <Container>
            {agents.map((agent, index) => (
                <Avatar
                    key={index}
                    $image={"https://budd-e.s3.eu-west-3.amazonaws.com/logo/logo_black.svg"}
                    onMouseEnter={() => setHoveredAgent(agent)}
                    onMouseLeave={() => setHoveredAgent(null)}
                />
            ))}

            {hoveredAgent && (
                <Card $show={hoveredAgent}>
                    <CardTitle>{hoveredAgent.name}</CardTitle>
                    <CardDescription>{hoveredAgent.description}</CardDescription>
                </Card>
            )}
        </Container>
    );
};

export default GlassEffect;
