export const getFileType = (extension) => {
    const extensionMap = {
        jpg: 'image',
        jpeg: 'image',
        png: 'image',
        gif: 'image',
        bmp: 'image',
        tiff: 'image',
        svg: 'image',
        mp3: 'audio',
        wav: 'audio',
        aac: 'audio',
        pdf: 'pdf',
        json: 'json',
        csv: 'csv',
    };
    return extensionMap[extension.toLowerCase()] || 'file';
};

export const isImage = (extension) => {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'svg'];
    return imageExtensions.includes(extension.toLowerCase());
};

export const hexToRGBA = (hex, alpha) => {
    if (hex.length < 7) {
        hex = hex[0] + hex[1].repeat(6)
    }
    let r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    } else {
        return `rgb(${r}, ${g}, ${b})`;
    }
};