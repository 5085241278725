// src/Chat.js

import React, { useCallback, useEffect, useRef, useState } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import Message from './Message.js';
import { getFileType } from '../utils.js';
import styled from 'styled-components';
import InitialView from './InitialView.js';
import Header from './Header.js';
import Footer from './Footer.js';

const translation = {
    "unavailable": {
        "en": "Sorry, I'm unavailable for the moment",
        "fr": "Désolé, je ne suis actuellement pas disponible"
    },
    "had_problem": {
        "en": "Sorry, I had a problem, can you try again ?",
        "fr": "Désolé, j'ai eu un problème, pouvez-vous réessayer ?"
    },
    "need_approval": {
        "en": "We kindly request your consent to utilize your personal information. This information will not be retained and will solely be utilized for the purpose of facilitating a request on your behalf",
        "fr": "Nous sollicitons votre consentement pour utiliser vos informations personnelles. Ces informations ne seront pas conservées et seront uniquement utilisées pour faciliter une demande en votre nom"
    },
    "reject": {
        "en": "Reject",
        "fr": "Refuser"
    },
    "accept": {
        "en": "Accept",
        "fr": "Accepter"
    },
    "not_accepted": {
        "en": "The request had been canceled",
        "fr": "La requête a été annulée"
    },
    "need_help": {
        "en": "Frequently asked Questions",
        "fr": "Questions fréquentes"
    },
    "analyzing": {
        "en": "Analyzing your message...",
        "fr": "Analyse du message..."
    }
}


const ChatMessages = styled.div`
  padding-top: 10px;
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background-color: ${(props) => props.$backgroundColor || 'rgb(251, 251, 251)'};
  gap: 20px;
`;



function Chat({
    apiKey,
    hostname,
    sessionStorageAvailable,
    isSmartphone,
    configuration,
    test
}) {
    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState('');
    const [isInputDisabled, setIsInputDisabled] = useState(false);
    const [showWelcomeMessage, setShowWelcomeMessage] = useState(true);
    const [isUploadingFile, setIsUploadingFile] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [taskInputValues, setTaskInputValues] = useState({});

    const conversationId = useRef(null);
    const inputMessageRef = useRef(null);
    const messagesEndRef = useRef(null);
    const webSocketRef = useRef(null);
    const chatContainerRef = useRef(null);
    const messageQueue = useRef([]);
    const tasksMessageIds = useRef({});
    const language = configuration.language;

    const { transcript, resetTranscript, browserSupportsSpeechRecognition, listening } =
        useSpeechRecognition();

    const handleTouchMove = (event) => {
        event.stopPropagation();
    };

    const handleInputFocus = () => {
        if (messagesEndRef.current && !showWelcomeMessage) {
            messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
        }
    };

    const handleAudioClick = useCallback(() => {
        if (listening) {
            SpeechRecognition.stopListening();
            resetTranscript();
        } else if (!isInputDisabled) {
            SpeechRecognition.startListening({ continuous: false });
        }
    }, [listening, isInputDisabled, resetTranscript]);

    const initChat = useCallback(async () => {
        if (!conversationId.current) {
            try {
                const response = await fetch(`${hostname}/api/chat/init?api_key=${apiKey}`, {
                    method: 'GET',
                });
                if (response.ok) {
                    const data = await response.json();
                    return data.conversation_id;
                }
            } catch (error) {
                console.error('Error:', error);
            }
        }
        return null;
    }, [hostname, apiKey]);

    const handleInputChange = (event) => {
        const inputText = event.target.value;
        setInputMessage(inputText);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleSendMessage();
        }
    };

    const storeConversationMessageInStorage = useCallback(
        (message) => {
            if (!sessionStorageAvailable || !conversationId.current) return;
            const pastMessagesStr = sessionStorage.getItem(`chatBundleMessages_${conversationId.current}`);
            let pastMessages = pastMessagesStr ? JSON.parse(pastMessagesStr) : [];
            pastMessages.push(message);
            if (pastMessages.length > 20) pastMessages.shift();
            sessionStorage.setItem(`chatBundleMessages_${conversationId.current}`, JSON.stringify(pastMessages));
        },
        [sessionStorageAvailable]
    );

    const updateMessageInStorage = useCallback(
        (message) => {
            if (!sessionStorageAvailable || !conversationId.current) return;
            const pastMessagesStr = sessionStorage.getItem(`chatBundleMessages_${conversationId.current}`);
            let pastMessages = pastMessagesStr ? JSON.parse(pastMessagesStr) : [];
            if (pastMessages.length > 0) {
                pastMessages[pastMessages.length - 1] = message
            }
            sessionStorage.setItem(`chatBundleMessages_${conversationId.current}`, JSON.stringify(pastMessages));
        },
        [sessionStorageAvailable]
    );

    const getSessionStorageKey = useCallback(() => test ? 'chatBundleConversationIdTest' : 'chatBundleConversationId', [test]);

    const fetchConversationIdFromStorage = useCallback(() => {
        return sessionStorageAvailable ? sessionStorage.getItem(getSessionStorageKey()) : null;
    }, [sessionStorageAvailable, getSessionStorageKey]);

    const storeConversationIdInStorage = useCallback(() => {
        if (sessionStorageAvailable && conversationId.current) {
            sessionStorage.setItem(getSessionStorageKey(), conversationId.current);
        }
    }, [sessionStorageAvailable, getSessionStorageKey]);

    const sendMessageFromForm = useCallback(
        (message) => {
            const bodyMessage = {
                content: message,
                additional_data: {},
            };
            const payload = {
                conversation_id: conversationId.current,
                api_key: apiKey,
                message: bodyMessage,
            };
            webSocketRef.current.send(JSON.stringify(payload));
        },
        [apiKey]
    );

    const sendMessage = useCallback(
        (message, additional_data = {}) => {
            if (selectedFile) {
                additional_data.file = { ...selectedFile };
                setSelectedFile(null);
            }
            const bodyMessage = {
                content: message,
                additional_data: additional_data,
            };
            const payload = {
                conversation_id: conversationId.current,
                api_key: apiKey,
                message: bodyMessage,
            };
            webSocketRef.current.send(JSON.stringify(payload));
            const placeholderMessage = {
                id: `placeholder-${Date.now()}`,
                sender: 'bot',
                data: translation.analyzing[language],
                type: 'placeholder',
            };
            setMessages((prev) => [...prev, placeholderMessage]);
        },
        [apiKey, selectedFile, language]
    );

    const connectWebSocket = useCallback(() => {
        const ws = new WebSocket(`${hostname.replace(/^https/, 'wss').replace(/^http/, 'ws')}/api/chat/ws`);
        webSocketRef.current = ws;

        ws.onopen = () => {
            console.log('WebSocket connected');
            while (messageQueue.current.length > 0) {
                const queueMessage = messageQueue.current.shift();
                sendMessage(queueMessage);
            }
        };

        ws.onmessage = (event) => {
            const payload = JSON.parse(event.data);
            const agentKey = payload.agent?.index ?? '__no_agent__';

            if (payload.tasks) {
                const tasksMessage = {
                    id: `tasks-${Date.now()}`,
                    sender: 'bot',
                    data: payload.tasks,
                    agent: payload.agent,
                    message: payload.id,
                    type: 'tasks',
                };

                tasksMessageIds.current[agentKey] = tasksMessage.id;

                setMessages((prevMessages) => {
                    const messagesWithoutPlaceholder = prevMessages.filter((msg) => msg.type !== 'placeholder');
                    const newMessages = [...messagesWithoutPlaceholder, tasksMessage];
                    return newMessages;
                });
                storeConversationMessageInStorage(tasksMessage);
            }

            if (payload.task_update) {
                const currentTaskMessageId = tasksMessageIds.current[agentKey];
                if (!currentTaskMessageId) {
                    return;
                }

                setMessages((prevMessages) =>
                    prevMessages.map((msg) => {
                        if (msg.type === 'tasks' && msg.id === currentTaskMessageId && msg.agent.index === agentKey) {
                            const updatedTasks = msg.data.map((task) =>
                                task.id === payload.task_update.id ? payload.task_update : task
                            );
                            updateMessageInStorage({ ...msg, data: updatedTasks });
                            return { ...msg, data: updatedTasks };
                        } else {
                            return msg;
                        }
                    })
                );
                setIsInputDisabled(false);
            }

            if (payload.error) {
                setMessages((prev) => [
                    ...prev,
                    {
                        id: `error-${Date.now()}`,
                        data: payload.error,
                        type: 'text',
                        sender: 'bot',
                        message: payload.id,
                        additional_data: { error: true },
                    },
                ]);
                setIsInputDisabled(false);
            }
        };

        ws.onerror = (error) => {
            console.error('WebSocket error:', error);
        };

        ws.onclose = () => {
            console.log('WebSocket disconnected');
            setIsInputDisabled(false);
        };
    }, [sendMessage, storeConversationMessageInStorage, updateMessageInStorage, hostname
    ]);

    const handleSendMessage = useCallback(
        async (event, forceMessage) => {
            if (event) event.preventDefault();

            if (inputMessage.trim() === '' && !forceMessage && !selectedFile) return;

            if (showWelcomeMessage) setShowWelcomeMessage(false);
            const message = forceMessage || inputMessage;
            setIsInputDisabled(true);
            setInputMessage('');
            resetTranscript();
            SpeechRecognition.stopListening();

            const dataFile = selectedFile
                ? { content: selectedFile.url, type: getFileType(selectedFile.extension) }
                : null;
            const userMessage = {
                data: message,
                type: 'text',
                sender: 'user',
                additional_data: { file: dataFile || null },
            };
            setMessages((prev) => [...prev, userMessage]);

            if (!conversationId || !conversationId.current) {
                conversationId.current = fetchConversationIdFromStorage() || (await initChat());
                if (!conversationId.current) {
                    setMessages((prev) => [
                        ...prev,
                        { data: [{ id: 0, title: "Error", "next": null, inputs: {}, missing_inputs: [], state: "error", response: { type: "text", content: translation.unavailable[configuration.language] } }], type: 'tasks', sender: 'bot' },
                    ]);
                    setIsInputDisabled(false);
                    return;
                }
                storeConversationIdInStorage();
            }
            if (webSocketRef.current && webSocketRef.current.readyState === WebSocket.CLOSED) {
                webSocketRef.current = null;
            }
            if (!webSocketRef.current) {
                connectWebSocket();
            }

            storeConversationMessageInStorage(userMessage);

            if (webSocketRef.current && webSocketRef.current.readyState === WebSocket.OPEN) {
                sendMessage(message);
            } else {
                messageQueue.current.push(message);
            }
        },
        [
            fetchConversationIdFromStorage,
            initChat,
            inputMessage,
            configuration,
            sendMessage,
            showWelcomeMessage,
            storeConversationMessageInStorage,
            storeConversationIdInStorage,
            resetTranscript,
            selectedFile,
            connectWebSocket,
        ]
    );

    useEffect(() => {
        if (messagesEndRef.current && !showWelcomeMessage) {
            messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
        }
    }, [messages, showWelcomeMessage]);

    useEffect(() => {
        const loadConversationMessageInStorage = () => {
            if (!conversationId.current) {
                conversationId.current = fetchConversationIdFromStorage()
            }
            if (!sessionStorageAvailable || !conversationId.current) return;
            const pastMessagesStr = sessionStorage.getItem(`chatBundleMessages_${conversationId.current}`);
            if (pastMessagesStr) {
                const pastMessages = JSON.parse(pastMessagesStr);
                if (pastMessages.length > 0) setMessages(pastMessages);
            }
        };
        window.sendMessageFromForm = sendMessageFromForm;
        const pastConversationId = fetchConversationIdFromStorage();
        if (pastConversationId) {
            loadConversationMessageInStorage(pastConversationId);
            setShowWelcomeMessage(false);
        }
        return () => {
            delete window.sendMessageFromForm;
        };
    }, [sendMessageFromForm, fetchConversationIdFromStorage, sessionStorageAvailable]);

    useEffect(() => {
        if ((!listening && transcript.length > 0)) {
            handleSendMessage();
        } else if (listening) {
            setInputMessage(transcript);
        }
    }, [transcript, listening, handleSendMessage]);

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        setIsUploadingFile(true);
        const formData = new FormData();
        formData.append('file', file);
        try {
            const response = await fetch(`${hostname}/api/upload_file?api_key=${apiKey}`, {
                method: 'POST',
                body: formData,
            });
            if (response.ok) {
                const data = await response.json();
                setSelectedFile(data);
            }
        } catch (error) {
            console.error('Error:', error);
        }
        e.target.value = '';
        setIsUploadingFile(false);
    };


    const handleTaskInputChange = (e, taskId) => {
        const { name, value } = e.target;
        setTaskInputValues((prev) => ({
            ...prev,
            [taskId]: {
                ...prev[taskId],
                [name]: value,
            },
        }));
    };

    const handleTaskInputSubmit = (e, taskId, agent = null) => {
        e.preventDefault();
        const inputValues = taskInputValues[taskId];
        if (!inputValues) return;

        const bodyMessage = {
            content: JSON.stringify(inputValues),
            additional_data: { agent_data: agent },
        };
        const payload = {
            conversation_id: conversationId.current,
            api_key: apiKey,
            message: bodyMessage,
        };

        if (webSocketRef.current && webSocketRef.current.readyState === WebSocket.OPEN) {
            webSocketRef.current.send(JSON.stringify(payload));
        } else {
            connectWebSocket();
            messageQueue.current.push(JSON.stringify(payload));
        }

        setTaskInputValues((prev) => ({
            ...prev,
            [taskId]: {},
        }));

        // Déterminer la clé agent
        const agentKey = agent?.index ?? '__no_agent__';
        const currentTaskMessageId = tasksMessageIds.current[agentKey];

        // Mettre à jour l'état de la task concernée en "running"
        setMessages((prevMessages) =>
            prevMessages.map((msg) => {
                if (msg.type === 'tasks' && msg.id === currentTaskMessageId) {
                    const updatedTasks = msg.data.map((task) => {
                        if (task.id === taskId) {
                            return {
                                ...task,
                                state: 'running',
                            };
                        }
                        return task;
                    });
                    updateMessageInStorage({ ...msg, data: updatedTasks });
                    return { ...msg, data: updatedTasks };
                } else {
                    return msg;
                }
            })
        );
    };

    const finalStructure = [];

    const groupedMessages = {};
    const insertedGroups = new Set();

    for (const [, message] of messages.entries()) {
        if (message.sender === 'bot' && message.message) {
            const messageId = message.message;
            const agentId = message.agent?.index;

            if (!groupedMessages[messageId]) {
                groupedMessages[messageId] = {};
            }
            if (!groupedMessages[messageId][agentId]) {
                groupedMessages[messageId][agentId] = [];
            }

            groupedMessages[messageId][agentId].push(message);
            if (!insertedGroups.has(messageId)) {
                finalStructure.push({ type: 'group', messageId });
                insertedGroups.add(messageId);
            }
        } else {
            finalStructure.push({ type: 'single', message });
        }
    }

    return (
        <div
            id="chat-container"
            ref={chatContainerRef}
        >
            <Header
                showWelcomeMessage={showWelcomeMessage}
                configuration={configuration}
                setShowWelcomeMessage={setShowWelcomeMessage}
                messages={messages}
            />
            <ChatMessages
                onTouchMove={handleTouchMove}
                ref={messagesEndRef}
                $backgroundColor={configuration.message_background_color || '#fbfbfb'}
            >
                {showWelcomeMessage &&
                    (
                        <InitialView
                            configuration={configuration}
                            handleSendMessage={handleSendMessage}

                        />
                    )}
                {!showWelcomeMessage &&
                    finalStructure.map((item, i) => {
                        if (item.type === 'single') {
                            // C'est un message isolé (utilisateur ou autre)
                            return (
                                <Message
                                    key={item.message.id || i}
                                    message={item.message}
                                    configuration={configuration}
                                    hostname={hostname}
                                    apiKey={apiKey}
                                    taskInputValues={taskInputValues}
                                    handleTaskInputChange={handleTaskInputChange}
                                    handleTaskInputSubmit={handleTaskInputSubmit}
                                    updateMessageInStorage={updateMessageInStorage}
                                    setMessages={setMessages}
                                    setIsInputDisabled={setIsInputDisabled}
                                />
                            );
                        } else if (item.type === 'group') {
                            // Groupe de messages bot groupés par messageId
                            const messageId = item.messageId;
                            const agentsGroups = groupedMessages[messageId];
                            return (
                                <div key={messageId} className="message-group" style={{
                                    backgroundColor: configuration.agent_message_background_color || 'transparent',
                                }}>
                                    {Object.entries(agentsGroups).map(([agentId, agentMessages]) => (
                                        <div key={agentId} className="agent-group">
                                            {/* Header de l'agent */}
                                            <div className="agent-header">
                                                <div className="agent-avatar" style={{ backgroundColor: configuration.avatar_background_color }}>
                                                    <img
                                                        src={configuration.avatar_logo}
                                                        alt={configuration.agents[agentMessages[0].agent.uuid].name}
                                                    />
                                                </div>
                                                <div className="agent-name">{configuration.agents[agentMessages[0].agent.uuid].name}</div>
                                            </div>

                                            {/* Messages de l'agent */}
                                            < div className="agent-messages" >
                                                {
                                                    agentMessages.map((message, index) => (
                                                        <Message
                                                            key={message.id || index}
                                                            agent={message.agent}
                                                            message={message}
                                                            configuration={configuration}
                                                            hostname={hostname}
                                                            apiKey={apiKey}
                                                            taskInputValues={taskInputValues}
                                                            handleTaskInputChange={handleTaskInputChange}
                                                            handleTaskInputSubmit={handleTaskInputSubmit}
                                                            updateMessageInStorage={updateMessageInStorage}
                                                            setMessages={setMessages}
                                                            setIsInputDisabled={setIsInputDisabled}
                                                            inGroup={true}
                                                        />
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    ))
                                    }
                                </div >
                            );
                        }

                        return null;
                    })}
            </ChatMessages >
            <Footer
                isInputDisabled={isInputDisabled}
                inputMessage={inputMessage}
                handleInputChange={handleInputChange}
                handleKeyPress={handleKeyPress}
                configuration={configuration}
                handleInputFocus={handleInputFocus}
                isUploadingFile={isUploadingFile}
                selectedFile={selectedFile}
                handleFileChange={handleFileChange}
                handleAudioClick={handleAudioClick}
                handleSendMessage={handleSendMessage}
                browserSupportsSpeechRecognition={browserSupportsSpeechRecognition}
                listening={listening}
                isSmartphone={isSmartphone}
                inputMessageRef={inputMessageRef}
                setSelectedFile={setSelectedFile}
            />
        </div >
    );
}

export default Chat;
